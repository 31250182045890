function openHelperShelf(shelf) {
  shelf.style.height = `${shelf.scrollHeight}px`
  shelf.dataset.helperShelf = "open"
  shelf.tabIndex = 0
  setTimeout(() => {
    shelf.style.overflow = "visible"
    shelf.style.height = "auto"
  }, 300)
}

function closeHelperShelf(shelf) {
  shelf.style.height = `${shelf.scrollHeight}px`
  shelf.style.overflow = "hidden"
  shelf.tabIndex = "-1"
  setTimeout(() => {
    shelf.style.height = "0px"
    shelf.dataset.helperShelf = "closed"
  }, 0)
}

function handleHelperShelfTriggerClick() {
  jQuery(document).on("click", "[data-helper-shelf-id]", (event) => {
    const shelf = document.getElementById(event.currentTarget.dataset.helperShelfId)

    const shelfWasClosed = shelf && shelf.dataset.helperShelf === "closed"

    const shelves = [...document.querySelectorAll(".helper-shelf[data-helper-shelf='open']")]

    shelves.forEach(closeHelperShelf)

    if (shelfWasClosed) {
      openHelperShelf(shelf)
    }
  })
}

function handleHelperShelfCloseClick() {
  jQuery(document).on("click", ".helper-shelf-close", (event) => {
    const shelf = jQuery(event.currentTarget).closest(".helper-shelf").get(0)

    shelf && closeHelperShelf(shelf)
  })
}

export function run() {
  handleHelperShelfTriggerClick()
  handleHelperShelfCloseClick()
}

export default exports
