import React from "react"
import { object, func } from "prop-types"
import _ from "lodash"
import classNames from "classnames"
import moment from "moment"
import RepeatableSchedules from "shared/runners/repeatable_schedules"
import SelectInput from "shared/components/select_input"

export default class RepeatableScheduleInput extends React.Component {
  static propTypes = {
    schedule: object.isRequired,
    onChange: func.isRequired,
  }

  handleChange = (change) => {
    this.props.onChange(this.getJSONValue(_.extend(this.getComputedState(), change)))
  }

  handleDayChange = (event) => {
    this.handleChange({ day: parseInt(event.target.value, 10) })
  }

  handleFrequencyChange = (event) => {
    this.handleChange({ frequency: event.target.value })
  }

  handleStartDateChange = (event) => {
    this.handleChange({ start_after: event.target.value })
  }

  handleTwiceMonthlyChange = (event) => {
    this.handleChange({ twice_monthly_key: event.target.value })
  }

  handleWeekdayChange = (event) => {
    this.handleChange({ weekday: parseInt(event.target.value, 10) })
  }

  getComputedState = () => {
    return {
      frequency: this.getFrequency(),
      day: this.getDay(),
      weekday: this.getWeekday(),
      start_after: this.getStartDate(),
      twice_monthly_key: this.getTwiceMonthlyKey(),
    }
  }

  getDay = () => {
    if (this.getFrequency() === "monthly") {
      return this.props.schedule.day_in_month.day
    } else {
      return _.values(RepeatableSchedules.days)[0]
    }
  }

  getFrequency = () => {
    return RepeatableSchedules.getFrequency(this.props.schedule)
  }

  getFrequencyWrapperClass = () => {
    return classNames("my-2 d-f@md fw-w fw-n@md ai-c", {
      "recurring-weekly-wrapper": this.getFrequency() === "weekly",
      "recurring-biweekly-wrapper": this.getFrequency() === "biweekly",
      "recurring-monthly-wrapper": this.getFrequency() === "monthly",
      "recurring-twice-monthly-wrapper": this.getFrequency() === "twice-monthly",
    })
  }

  getJSONValue = (state) => {
    switch (state.frequency) {
      case "weekly":
        return { weekday: { weekday: state.weekday } }
      case "biweekly":
        return {
          biweekly: { weekday: state.weekday, start_after: state.start_after },
        }
      case "monthly":
        return { day_in_month: { day: state.day } }
      case "twice-monthly":
        return RepeatableSchedules.twiceMonthlyOptions[state.twice_monthly_key]
      default:
        return {}
    }
  }

  getStartDate = () => {
    if (this.getFrequency() === "biweekly") {
      return this.props.schedule.biweekly.start_after
    } else {
      return moment().format("YYYY-MM-DD")
    }
  }

  getTwiceMonthlyKey = () => {
    if (this.getFrequency() === "twice-monthly") {
      return RepeatableSchedules.getTwiceMonthlyKey(this.props.schedule)
    } else {
      return _.keys(RepeatableSchedules.twiceMonthlyOptions)[0]
    }
  }

  getWeekday = () => {
    switch (this.getFrequency()) {
      case "weekly":
        if (this.props.schedule.weekday) {
          return this.props.schedule.weekday.weekday
        } else {
          return _.values(RepeatableSchedules.weekdays)[0]
        }
      case "biweekly":
        return this.props.schedule.biweekly.weekday
      default:
        return _.values(RepeatableSchedules.weekdays)[0]
    }
  }

  renderFrequencyOptions = () => {
    switch (this.getFrequency()) {
      case "weekly":
        return (
          <SelectInput
            options={RepeatableSchedules.weekdays}
            value={this.getWeekday()}
            onChange={this.handleWeekdayChange}
            aria-label="weekday for donation"
          />
        )
      case "biweekly":
        return (
          <SelectInput
            options={RepeatableSchedules.weekdays}
            value={this.getWeekday()}
            onChange={this.handleWeekdayChange}
            aria-label="weekday for donation"
          />
        )
      case "monthly":
        return (
          <SelectInput
            options={RepeatableSchedules.days}
            value={this.getDay()}
            onChange={this.handleDayChange}
            data-cy="day_of_month_select"
            aria-label="day of month for donation"
          />
        )
      case "twice-monthly":
        return (
          <SelectInput
            options={Object.keys(RepeatableSchedules.twiceMonthlyOptions)}
            value={this.getTwiceMonthlyKey()}
            onChange={this.handleTwiceMonthlyChange}
            aria-label="days of month for donation"
          />
        )
      default:
        return null
    }
  }

  render() {
    return (
      <div className={this.getFrequencyWrapperClass()}>
        <div className="f-1">
          <SelectInput
            options={RepeatableSchedules.frequencies}
            value={this.getFrequency()}
            onChange={this.handleFrequencyChange}
            data-cy="frequency_select"
            aria-label="recurrence frequency options"
          />
        </div>
        <div className="p-1@md py-1 recurring-preposition">
          <span className="text">
            {RepeatableSchedules.getJoiningPreposition(this.getFrequency())}
          </span>
        </div>
        <div className="f-1">{this.renderFrequencyOptions()}</div>
      </div>
    )
  }
}
