import React from "react"
import SelectInput from "shared/components/select_input"
import i18n from "shared/runners/i18n"
import moment from "moment"
import { object, string, func } from "prop-types"

export default class ChooseNextDonation extends React.Component {
  static propTypes = {
    schedule: object.isRequired,
    next_occurrence: string,
    onChange: func.isRequired,
    getStartDates: func.isRequired,
    label: string.isRequired,
  }

  state = {
    options: null,
  }

  componentDidMount() {
    this.refreshOptions(this.props.schedule)
  }

  componentWillReceiveProps(nextProps) {
    const currentSchedule = this.props.schedule
    const nextSchedule = nextProps.schedule

    if (!_.isEqual(currentSchedule, nextSchedule)) {
      this.refreshOptions(nextSchedule)
    }
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value)
  }

  isLoading = () => {
    return !this.state.options
  }

  refreshOptions = (schedule) => {
    this.setState({ options: null })

    this.props
      .getStartDates({
        schedule: JSON.stringify(schedule),
        limit: 10,
      })
      .then(
        ({ start_dates }) => this.setState({ options: start_dates }),
        () => this.setState({ options: null })
      )
  }

  getOptions = () => {
    if (this.isLoading()) {
      return ["loading..."]
    } else {
      return this.state.options.reduce((obj, option) => {
        obj[moment(option).format(i18n.dateFormatMoment)] = option
        return obj
      }, {})
    }
  }

  render() {
    return (
      <div>
        <label htmlFor="next_donation">{this.props.label}</label>
        <SelectInput
          options={this.getOptions()}
          value={this.props.next_occurrence || this.getOptions()[0]}
          disabled={this.isLoading()}
          onChange={this.handleChange}
          className="select"
          id="next_donation"
        />
      </div>
    )
  }
}
