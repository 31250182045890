const formSelector = 'form[method="get"]:not([data-remote="true"]):not([data-no-turboform="true"])'

function handleFormSubmission(event) {
  event.preventDefault()
  const form = jQuery(event.target)
  const params = form.serialize()
  let action

  if (typeof form.attr("action") === "undefined") {
    action = document.location.pathname
  } else {
    action = form.attr("action")
  }

  Turbolinks.visit(`${action}?${params}`)
}

export function run() {
  jQuery(document).on("turbolinks:load modal:load", () => {
    disable()
    enable()
  })
}

function enable() {
  jQuery(formSelector).on({ submit: handleFormSubmission })
}

function disable() {
  jQuery(formSelector).off({ submit: handleFormSubmission })
}

export default exports
