/* eslint-disable react/prefer-es6-class */

import React from "react"
import createReactClass from "create-react-class"
import i18n from "shared/runners/i18n"
import bowser from "bowser"
import moment from "moment"
import _ from "lodash"
import { string, func, number, object } from "prop-types"

// Passes almost all props along to the controlled hidden input and
// controlling text input, with some intelligence about the name,
// defaultValue, etc. These are the most common
const propTypes = {
  id: string,
  className: string,
  defaultValue: string,
  name: string,
  onChange: func,
  value: string,
  minDate: string,
  maxDate: string,
  pastYearsToShow: number,
  futureYearsToShow: number,
  style: object,
}

const DateInput = createReactClass({
  propTypes,

  statics: {
    useSystemDatepicker: bowser.ios || bowser.android,
  },

  getDefaultProps() {
    return {
      pastYearsToShow: 20,
      futureYearsToShow: 2,
    }
  },

  getInitialState() {
    return {
      railsDateValue: this.props.value || this.props.defaultValue,
      generatedId: _.uniqueId("react-jquery-ui-datepicker"),
      datepickerAttached: false,
      datepickerOptions: this.getJQueryDatepickerOptions(),
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hasOwnProperty("value")) {
      return
    }

    // update the hidden field value if parent props have changed
    if (nextProps.value !== this.props.value) {
      this.setState({
        railsDateValue: nextProps.value,
      })
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(_.omit(this.props, "onChange"), _.omit(nextProps, "onChange")) ||
      !_.isEqual(this.state, nextState)
    )
  },

  componentDidUpdate() {
    this.updateDatepicker()
  },

  componentWillUnmount() {
    this.detachDatepicker()
  },

  handleDatepickerFocus() {
    this.attachDatepicker()
    jQuery(this.refs.input).datepicker("show")
  },

  handleDatepickerBlur(event) {
    const input = jQuery(this.refs.input)
    const { relatedTarget } = event

    if (event.relatedTarget && !input.datepicker("widget").get(0).contains(relatedTarget)) {
      jQuery(this.refs.input).datepicker("hide")
    }
  },

  handleJQueryDatepickerChange(date) {
    this.setState({
      railsDateValue: moment(date, i18n.dateFormatMoment).format(i18n.dateFormatRails),
    })

    if (this.props.onChange) {
      const fakeEvent = { target: this.refs.hidden }
      this.props.onChange(fakeEvent)
    }
  },

  handleJQueryDatepickerClose() {
    jQuery(this.refs.input).focus()
  },

  handleInputClick() {
    jQuery(this.refs.input).datepicker("show")
  },

  attachDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }
    if (this.state.datepickerAttached) {
      return
    }

    jQuery(this.refs.input).datepicker(this.getJQueryDatepickerOptions())
    this.setState({ datepickerAttached: true })
  },

  updateDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }

    const datepickerOptions = this.getJQueryDatepickerOptions()

    if (_.isEqual(this.state.datepickerOptions, datepickerOptions)) {
      return
    }

    jQuery(this.refs.input).datepicker("option", datepickerOptions)
    this.setState({ datepickerOptions })
  },

  detachDatepicker() {
    if (DateInput.useSystemDatepicker) {
      return
    }

    const $input = jQuery(this.refs.input)

    $input.datepicker("hide")
    $input.datepicker("destroy")
  },

  getJQueryDatepickerOptions() {
    const options = {
      dateFormat: i18n.dateFormatJQuery,
      changeMonth: true,
      changeYear: true,
      yearRange: `-${this.props.pastYearsToShow}:+${this.props.futureYearsToShow}`,
      onSelect: this.handleJQueryDatepickerChange,
      onClose: this.handleJQueryDatepickerClose,
    }

    if (this.props.minDate) {
      options.minDate = moment(this.props.minDate).toDate()
    }
    if (this.props.maxDate) {
      options.maxDate = moment(this.props.maxDate).toDate()
    }

    return options
  },

  getRailsHiddenInputProps() {
    const consumedProps = Object.keys(propTypes)

    return _.extend(_.omit(this.props, consumedProps), {
      name: this.props.name,
      readOnly: true,
      ref: "hidden",
      type: "hidden",
      value: this.state.railsDateValue || "",
    })
  },

  getJQueryDatepickerProps() {
    const consumedProps = Object.keys(propTypes)
    const value = this.state.railsDateValue
      ? moment(this.state.railsDateValue, i18n.dateFormatRails).format(i18n.dateFormatMoment)
      : ""

    return _.extend(_.omit(this.props, consumedProps), {
      className: this.props.className,
      readOnly: true,
      ref: "input",
      type: "text",
      value,
      id: this.props.id || this.state.generatedId,
    })
  },

  render() {
    if (DateInput.useSystemDatepicker) {
      const systemDatePickerProps = _.omit(this.props, "className")

      return (
        <input
          type="date"
          className={["giving-datepicker", this.props.className || "w-100%"].join(" ")}
          {...systemDatePickerProps}
        />
      )
    }

    return (
      <span>
        <input
          {...this.getJQueryDatepickerProps()}
          onFocus={this.handleDatepickerFocus}
          onBlur={this.handleDatepickerBlur}
          onClick={this.handleInputClick}
          className="w-100%"
          style={{ ...this.props.style }}
        />
        <input {...this.getRailsHiddenInputProps()} />
      </span>
    )
  },
})

export default DateInput
