import React from "react"
import renderReactModal from "shared/utils/render_react_modal"

let globalConfiguration
let globalReject
let globalResolve
let globalFailedAttempts = 0
let globalPromise
let globalInstance

export function run(configuration) {
  if (!configuration.publishable_key) {
    throw new Error("StripeWithGuard.run(configuration) was called without a publishable_key!")
  }

  if (globalPromise) {
    throw new Error("StripeWithGuard.run(configuration) should be called exactly once")
  }

  globalPromise = new Promise((resolve, reject) => {
    globalConfiguration = configuration
    globalResolve = resolve
    globalReject = reject
    attemptLoad()
  })
}

export function guard(func) {
  if (window.Stripe) {
    func()
  } else {
    handleCompleteFailureToLoad()
  }
}

export function promise() {
  if (!globalPromise) {
    throw new Error("StripeWithGuard.promise() called before StripeWithGuard.run(configuration)")
  }
  return globalPromise
}

function handleCompleteFailureToLoad() {
  const reload = () => window.location.reload()

  renderReactModal(
    <div className="modal">
      <div className="modal__header">
        <div className="modal-title">Sorry about this...</div>
      </div>
      <div className="modal__body">
        <p className="mb-3">
          We rely on Stripe to securely process payment information. Stripe works by loading
          JavaScript on our site. We've been trying to load Stripe's JavaScript, but haven't been
          able to! Sometimes this can be resolved by reloading the page, but more often this happens
          because ad-blockers are blocking Stripe's JavaScript from loading. If you continue to
          experience problems after reloading the page or adjusting ad-blocker settings, please{" "}
          <a href="mailto:support@planningcenter.com">email our support team</a>, and we'll take a
          look.
        </p>
        <p className="pt-3r">
          <a className="w-100% btn" onClick={reload}>
            Reload
          </a>
        </p>
      </div>
    </div>
  )
}

function attemptLoad() {
  const head = document.getElementsByTagName("head")[0]
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.src = "https://js.stripe.com/v3/"
  script.onload = handleLoad
  script.onerror = handleError
  head.appendChild(script)
}

function handleLoad() {
  globalInstance = Stripe(globalConfiguration.publishable_key, {
    stripeAccount: globalConfiguration.account_id,
  })
  globalResolve()
}

function handleError() {
  if (globalFailedAttempts >= 50) {
    globalReject()
  } else {
    globalFailedAttempts += 1
    attemptLoad()
  }
}

export function instance() {
  return globalInstance
}

export default exports
