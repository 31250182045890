import _ from "lodash"
// The ApiBase class clarifies your Api classes
//
// extend from ApiBase and use this.request() to make your requests
//
// this.request
//
// - Wraps jQuery.ajax in a Promise
// - Sets good defaults for requests, but let's the caller override anything
// - Aborts last request on every request
export default class ApiBase {
  abortLastRequest() {
    if (this.lastRequest) {
      this.lastRequest.abort()
    }
  }

  request(url, options = {}) {
    return new Promise((fulfill, reject) => {
      this.abortLastRequest()
      this.lastRequest = jQuery.ajax(
        url,
        _.merge(
          {
            type: "GET",
            success: fulfill,
            error: (jqXhr, textStatus, _errorThrown) => {
              if (textStatus !== "abort") {
                reject(jqXhr.responseJSON)
              }
            },
          },
          options
        )
      )
    })
  }
}
