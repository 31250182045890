import React from "react"
import _ from "lodash"
import { oneOfType, object, array, any } from "prop-types"

// Expects options to be an object where they keys serve as the text values and
// the values serve as the option values.
//
//    {
//      "US Dollar": "USD",
//      "British Pound": "GBP",
//      "Euro": "EUR",
//    }
//
//    <option key="USD" value="USD">US Dollar</option>
//    <option key="GBP" value="GBP">British Pound</option>
//    <option key="EUR" value="EUR">Euro</option>
//
//
// You can also pass a plain array if you want its values to work as both the
// text and option values.
//
//    [
//      "USD",
//      "GBP",
//      "EUR",
//    ]
//
//    <option key="USD" value="USD">USD</option>
//    <option key="GBP" value="GBP">GBP</option>
//    <option key="EUR" value="EUR">EUR</option>

export default class SelectInput extends React.Component {
  static propTypes = {
    options: oneOfType([object, array]).isRequired,
    value: any,
    defaultValue: any,
  }

  getRenderProps = () => {
    return _.omit(this.props, ["value", "defaultValue", "options"])
  }

  getValue = () => {
    return this.props.value || this.props.defaultValue
  }

  renderOptions = () => {
    const { options } = this.props

    if (!typeof options === "object") {
      return null
    }

    if (Array.isArray(options)) {
      return options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))
    } else {
      return Object.keys(options).map((key) => {
        return (
          <option key={options[key]} value={options[key]}>
            {key}
          </option>
        )
      })
    }
  }

  render() {
    return (
      <select value={this.getValue()} className="select" {...this.getRenderProps()}>
        {this.renderOptions()}
      </select>
    )
  }
}
