/* eslint-disable no-console,max-params,no-script-url */

import React from "react"
import renderSimpleModal from "shared/utils/render_simple_modal"
import ReactDOMServer from "react-dom/server"

const statusesToIgnore = [422]

function determineErrorMessage(status, error) {
  if (statusesToIgnore.includes(status)) {
    return null
  }

  if (status === 404 || error === 404) {
    return {
      title: "We can't find that page",
      message: (
        <div>
          <p>We couldn't find the requested page.</p>
          <p>
            If you think this is an error{" "}
            <a href="javascript:Helpdesk.load()">let our support team know</a>.
          </p>
          <p>
            Otherwise, head on back to <a href="/">your home page and try again.</a>
          </p>
        </div>
      ),
    }
  } else if (status === 429) {
    return {
      title: "Please try again later",
      message: (
        <div>
          <p>We can't process your request right now.</p>

          <p className="mt-1">If you were trying to donate, please try again later.</p>

          <p className="mt-1">
            For assistance, please email us at{" "}
            <a href="mailto:giving@planningcenteronline.com">giving@planningcenteronline.com</a>
          </p>

          <p className="mt-1">Sorry!</p>
        </div>
      ),
    }
  } else {
    return {
      title: "Something went wrong",
      message: (
        <div>
          <p>Uh oh. There was an error.</p>
          <p className="mt-1">
            If you were trying to donate please <strong>do not</strong> try again. We've been
            notified automatically and will work to get it resolved.
          </p>

          <p className="mt-1">
            For assistance, please email us at{" "}
            <a href="mailto:giving@planningcenteronline.com">giving@planningcenteronline.com</a>
          </p>

          <p className="mt-1">Sorry!</p>
        </div>
      ),
    }
  }
}

function handleUjsError(event) {
  const [body, textStatus, xhr] = event.detail
  const errorMessage = determineErrorMessage(xhr.status)

  if (errorMessage) {
    renderSimpleModal(errorMessage.title, ReactDOMServer.renderToString(errorMessage.message), {
      buttons: `<a onclick="window.location.reload()" class="sm-btn text-btn btn" type="button">Dismiss</a>`,
    })

    handleXHRError(xhr, textStatus, body)
  }
}

function handleXHRError(xhr, textStatus, responseBody) {
  const errorType = `ajax:error:${xhr.status}`
  const responseHeaders = xhr.getAllResponseHeaders()
  const error = new Error(textStatus)

  window.Bugsnag.notifyException(error, {
    details: {
      errorType,
      responseHeaders,
      responseText: responseBody,
    },
  })
}

function handleUjsBeforeSend(event) {
  const [_xhr, settings] = event.detail
  window.Bugsnag.leaveBreadcrumb("UJS beforeSend", settings)
}

export function run() {
  jQuery(document).on("ajax:error", handleUjsError).on("ajax:beforeSend", handleUjsBeforeSend)
}

export default exports
