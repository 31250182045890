let store = {}

export function run(features) {
  store = features
}

export function featureEnabled(name) {
  return store[name] === true
}

export default exports
