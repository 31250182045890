import React from "react"
import ReactDOM from "react-dom"
import { any, func } from "prop-types"
import _ from "lodash"

export default class MoneyInput extends React.Component {
  static propTypes = {
    value: any,
    defaultValue: any,
    onChange: func,
  }

  constructor(props) {
    super(props)
    this.inputEl = React.createRef()
  }

  state = {
    value: MoneyInput.sanitize(this.props.value || this.props.defaultValue),
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextValue = MoneyInput.sanitize(nextProps.value || nextProps.defaultValue)
    this.setState({
      value: nextValue,
    })
  }

  UNSAFE_componentWillUpdate() {
    if (document.activeElement === ReactDOM.findDOMNode(this)) {
      this.previousSelection = {
        start: ReactDOM.findDOMNode(this).selectionStart,
        end: ReactDOM.findDOMNode(this).selectionEnd,
      }
    } else {
      this.previousSelection = null
    }
  }

  componentDidUpdate() {
    if (this.previousSelection) {
      ReactDOM.findDOMNode(this).selectionStart = this.previousSelection.start
      ReactDOM.findDOMNode(this).selectionStart = this.previousSelection.end
    }
  }

  handleChange = (event) => {
    const nextValue = MoneyInput.sanitize(event.target.value)

    this.setState({ value: nextValue }, () => {
      if (this.props.onChange) {
        this.props.onChange({ target: ReactDOM.findDOMNode(this) })
      }
    })
  }

  static minimalTextValue(numberOrString) {
    const f = parseFloat(numberOrString)
    return _.isNaN(f) ? "" : f.toFixed(2).replace(/\.00$/, "")
  }

  static sanitize(numberIn) {
    let number = numberIn
    if (typeof number !== "string" && typeof number !== "number") {
      return ""
    }

    number = `${number}`
    let [dollars, cents] = number
      .toString()
      .replace(/[^\d.]/g, "")
      .split(".")

    if (dollars.match(/^0+$/)) {
      dollars = "0"
    } else if (dollars.length > 1) {
      // trim extra leading zeros ('02' becomes '2')
      // but empty string and a single zero are untouched
      dollars = _.trimStart(dollars, "0")
    } else if (dollars.length === 0 && typeof cents !== "undefined") {
      // add leading zero when cents are present ('.' becomes '0.')
      dollars = "0"
    }

    if (typeof cents === "undefined") {
      // There was no '.'
      return dollars
    } else {
      // '.' was anywhere
      return [dollars, cents.substr(0, 2)].join(".")
    }
  }

  getRenderProps = () => {
    return _.extend(_.omit(this.props, ["value", "defaultValue"]), {
      type: "text",
      value: this.state.value,
      onChange: this.handleChange,
    })
  }

  render() {
    return <input {...this.getRenderProps()} autoComplete="off" ref={this.inputEl} />
  }
}
